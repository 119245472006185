import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import Session from 'supertokens-web-js/recipe/session';

const ROLES_KEY = 'roles';
const CLAIMS_KEY = 'claims';
const USERID_KEY = 'userId';
const EMAIL_KEY = 'email';

@Injectable()
export class AuthService {
  constructor(private storageService: StorageService) {}

  public async getLoggedIn(): Promise<boolean> {
    return Session.doesSessionExist();
  }

  set role(role: string) {
    this.storageService.set(ROLES_KEY, role);
  }
  get role() {
    return this.storageService.get(ROLES_KEY);
  }

  set email(email: string) {
    this.storageService.set(EMAIL_KEY, email);
  }
  get email() {
    return this.storageService.get(EMAIL_KEY);
  }

  set userId(userId: string) {
    this.storageService.set(USERID_KEY, userId);
  }
  get userId() {
    return this.storageService.get(USERID_KEY);
  }

  set claims(claims: string[]) {
    if (claims) {
      this.storageService.set(CLAIMS_KEY, claims.join(','));
    }
  }

  get claims() {
    const claimsString = this.storageService.get(CLAIMS_KEY);
    return claimsString ? claimsString.split(',') : [];
  }

  clear() {
    this.role = '';
    this.userId = '';
    this.email = '';
    this.claims = [];
    this.storageService.remove(ROLES_KEY);
    this.storageService.remove(CLAIMS_KEY);
    this.storageService.remove(EMAIL_KEY);
    this.storageService.remove(USERID_KEY);
  }
}
