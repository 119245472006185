import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { StorageService } from './storage.service';

@Injectable()
export class HttpService {
  constructor(private http: HttpClient, private storage: StorageService) {}

  getHeaders() {
    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    });
    return headers;
  }

  getSecureHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${this.storage.token}`,
    });
    return headers;
  }

  private setUrl(url: string): string {
    return this.setUrlWithApi(url, environment.API);
  }

  private setUrlWithApi(url: string, api: string): string {
    if (url.startsWith('http')) {
      return url;
    }
    return api + url;
  }

  anonPostWithApi<T>(url: string, obj: any, api: string, forceJson: boolean = false) {
    const payload = obj ? JSON.stringify(obj) : null;
    const options = forceJson ? { headers: this.getHeaders() } : undefined;
    return this.http.post<T>(this.setUrlWithApi(url, api), payload, options).pipe(catchError(this.handleError));
  }

  get<T>(url: string): Observable<T> {
    const options = { headers: this.getSecureHeaders() };
    return this.http.get<T>(this.setUrl(url), options).pipe(catchError(this.handleError));
  }

  getWithApi<T>(url: string, api: string): Observable<T> {
    const options = { headers: this.getSecureHeaders() };
    return this.http.get<T>(this.setUrlWithApi(url, api), options).pipe(catchError(this.handleError));
  }

  postWithApi<T>(url: string, data: any, api: string, headers?: HttpHeaders): Observable<any> {
    const options = { headers: headers || this.getSecureHeaders() };
    const payload = data ? JSON.stringify(data) : null;
    return this.http.post<T>(this.setUrlWithApi(url, api), payload, options).pipe(catchError(this.handleError));
  }

  putWithApi<T>(url: string, data: any, api: string, headers?: HttpHeaders): Observable<any> {
    const options = { headers: headers || this.getSecureHeaders() };
    const payload = data ? JSON.stringify(data) : null;
    return this.http.put<T>(this.setUrlWithApi(url, api), payload, options).pipe(catchError(this.handleError));
  }

  deleteWithApi<T>(url: string, api: string): Observable<any> {
    const options = { headers: this.getSecureHeaders() };
    return this.http.delete<T>(this.setUrlWithApi(url, api), options).pipe(catchError(this.handleError));
  }

  private handleError(error: any) {
    return observableThrowError(error);
  }
}
