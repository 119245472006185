import { RoleAccessService } from './roleAccess.service';
import { HttpService } from './http.service';
import { ToastService } from './toast.service';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';
import { WebPageTemplatePipe } from '../pipes/webPageTemplate.pipe';

export const CORE_SERVICES = [
  HttpService,
  ToastService,
  AuthService,
  RoleAccessService,
  StorageService,
  WebPageTemplatePipe,
];

export { HttpService, ToastService, AuthService, RoleAccessService, StorageService };
