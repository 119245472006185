import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { RoleAccessService } from '../services/roleAccess.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router, private roleAccessService: RoleAccessService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let allowed: boolean = false;

    const loggedIn = await this.auth.getLoggedIn();

    if (loggedIn) {
      if (state.url == '/pages/dashboard') {
        allowed = true;
        return allowed;
      }

      if (this.roleAccessService.canAccessRoute(state.url)) {
        allowed = true;
      }

      if (allowed) {
        return true;
      }

      if (!allowed) {
        let defaultPage = this.roleAccessService.getDefaultPage();

        if (!defaultPage) {
          defaultPage = 'pages/dashboard';
        }

        this.router.navigate([defaultPage]);
        return false;
      }
    } else {
      this.auth.clear();
      this.router.navigate(['login']);
      return false;
    }
  }
}
